/**
 * 经纬度转为地址的方法
 * @param {*} lng
 * @param {*} lat
 * @param {*} isDetials
 */
export default function getPlace(lng, lat, isDetials) {
  return new Promise(function (resolve, reject) { // 做一些异步操作
    let lnglatXY = [lng, lat]
    AMap.service('AMap.Geocoder', function () { // 回调函数
      let geocoder = new AMap.Geocoder({})
      geocoder.getAddress(lnglatXY, function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          // 获得了有效的地址信息:
          // 即，result.regeocode.formattedAddress
          if (isDetials) {
            resolve(result.regeocode.addressComponent)
          } else {
            resolve(result.regeocode.formattedAddress)
          }
        } else {
          resolve('')
        }
      })
    })
  })
}
